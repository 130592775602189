@use '../includes' as *;

.title {
  @include h3;

  margin-block-end: $g24;
}

.link_list_item {
  margin-block-end: $g24;
}

.link {
  font-size: $f16;
  font-weight: $bold;

  svg {
    width: 19px;
    height: 16px;
    margin-inline-start: $g8;
    transform: translateY(2px);
    transition-duration: var(--duration-half);
    fill: $fog;
  }

  &:hover {
    svg {
      fill: $pebble;
    }
  }
}
